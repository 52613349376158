<template>
  <el-dialog
    width="82%"
    center
    top="70px"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
  >
    <h6>基础信息</h6>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="11">
        <span>项目名称</span>
        <div>
          <el-input
            size="medium"
            v-model="deploy.title"
            placeholder="请输入内容"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>项目难度</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.level_id"
            placeholder="请选择"
            :disabled="!isDisabled"
          >
            <el-option
              v-for="item in difficultyList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="24" class="radio">
        <span>项目类别</span>
        <div>
          <el-radio-group v-model="deploy.type_id" :disabled="!isDisabled">
            <el-radio :label="1">标准项目</el-radio>
            <el-radio :label="2">短期项目</el-radio>
            <el-radio :label="3">插画视频项目</el-radio>
          </el-radio-group>
        </div>
      </el-col>
      <el-col :span="24">
        <span>项目注释</span>
        <div>
          <el-input
            type="textarea"
            :rows="4"
            resize="none"
            placeholder="请输入内容"
            v-model="deploy.descr"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="24" class="prepend">
        <span>相关链接</span>
        <div>
          <el-input v-model="deploy.alink" :disabled="!isDisabled">
            <template slot="prepend">http://</template>
          </el-input>
        </div>
      </el-col>
      <el-col :span="24" class="upload">
        <span>上传资料附件</span>
        <div>
          <el-upload
            action="//ghf.site.yscase.com/api/v1.index/uploadFile"
            list-type="picture-card"
            :headers="token"
            :on-success="success"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :file-list="fileList"
            :disabled="!isDisabled"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <!-- <el-dialog :visible.sync="dialogVisible">
              <template #file="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="imgsrc(file)"
                />
              </template>
            </el-dialog> -->
        </div>
      </el-col>
    </el-row>
    <h6>客户信息</h6>
    <el-divider></el-divider>
    <el-row>
      <!-- <el-col class="logoBox" :span="24">
        <span>客户LOGO</span>
        <div>
          <el-upload
            class="avatar-uploader"
            action="//ghf.site.yscase.com/api/v1.index/uploadPic"
            :headers="token"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :disabled="!isDisabled"
          >
            <img
              v-if="customer.thumb"
              :src="'//ghf.site.yscase.com' + customer.thumb"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </el-col> -->
      <el-col :span="11">
        <span>客户名称</span>
        <div>
          <el-input
            size="medium"
            v-model="customer.name"
            placeholder="请输入内容"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>客户地址</span>
        <div>
          <el-input
            size="medium"
            v-model="customer.address"
            placeholder="请输入客户详细地址"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <span>联系人</span>
        <div>
          <el-input
            size="medium"
            v-model="customer.lxr"
            placeholder="请输入内容"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>联系方式</span>
        <div>
          <el-input
            size="medium"
            v-model="customer.tel"
            placeholder="请输入内容"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
      <el-col :span="24">
        <span>客户备注</span>
        <div>
          <el-input
            type="textarea"
            :rows="4"
            resize="none"
            placeholder="请输入内容"
            v-model="customer.note"
            :disabled="!isDisabled"
          ></el-input>
        </div>
      </el-col>
    </el-row>
    <h6>人员设置</h6>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="11">
        <span>PM人员</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.id_pm"
            placeholder="请选择"
            :disabled="!isDisabled"
            multiple
          >
            <el-option
              v-for="item in id_pm"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>主美</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.id_lead"
            placeholder="待分配"
            :disabled="fenpei || !isDisabled"
          >
            <el-option
              v-for="item in id_lead"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <span>组长</span>
        <div>
          <el-select
            :disabled="fenpei || !isDisabled"
            size="medium"
            v-model="deploy.id_headman"
            placeholder="待分配"
          >
            <el-option
              v-for="item in id_headman"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>画师</span>
        <div>
          <el-select
            :disabled="fenpei || !isDisabled"
            size="medium"
            v-model="workers"
            multiple
            :multiple-limit="6"
            placeholder="待分配"
          >
            <el-option
              v-for="item in id_workers"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <span>商务</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.id_business"
            placeholder="请选择"
            :disabled="!isDisabled"
          >
            <el-option
              v-for="item in id_business"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <!-- <el-col :span="2" style="height:40px"></el-col>
      <el-col :span="11">
        <span>联系人</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.id_contact"
            placeholder="请选择"
            :disabled="!isDisabled"
          >
            <el-option
              v-for="item in id_contact"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-col> -->
    </el-row>
    <h6>时间设置</h6>
    <el-divider></el-divider>
    <el-row>
      <!-- <el-col :span="11">
        <span>开始时间</span>
        <div>
          <el-date-picker
            v-model="deploy.date_start"
            type="date"
            size="medium"
            value-format="yyyy-MM-dd"
            placeholder="设置项目开始时间"
            :disabled="!isDisabled"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="2" style="height:40px"></el-col> -->
      <el-col :span="11">
        <span>草稿时间</span>
        <div>
          <el-date-picker
            v-model="draftTime"
            type="daterange"
            size="medium"
            :disabled="!(deploy.progress < 2) || !isDisabled"
            value-format="yyyy-MM-dd"
            start-placeholder="草稿开始日期"
            end-placeholder="草稿结束日期"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>色稿时间</span>
        <div>
          <el-date-picker
            v-model="colorTime"
            type="daterange"
            size="medium"
            :disabled="!(deploy.progress < 3) || !isDisabled"
            value-format="yyyy-MM-dd"
            start-placeholder="色稿开始日期"
            end-placeholder="色稿结束日期"
            :picker-options="pickerOptions1"
          >
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="11">
        <span>细化时间</span>
        <div>
          <el-date-picker
            v-model="refiningTime"
            type="daterange"
            size="medium"
            :disabled="!(deploy.progress <= 3) || !isDisabled"
            value-format="yyyy-MM-dd"
            start-placeholder="细化开始日期"
            end-placeholder="细化结束日期"
            :picker-options="pickerOptions2"
          >
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>结束时间</span>
        <div>
          <el-date-picker
            v-model="deploy.date_end"
            type="date"
            size="medium"
            value-format="yyyy-MM-dd"
            placeholder="设置项目结束时间"
            :picker-options="pickerOptions3"
            :disabled="!isDisabled"
          >
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <h6>状态设置</h6>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="11">
        <span>当前进度</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.progress"
            placeholder="请选择"
            :disabled="!isDisabled"
          >
            <el-option
              v-for="item in progressList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="2" style="height: 40px"></el-col>
      <el-col :span="11">
        <span>紧急状态</span>
        <div>
          <el-select
            size="medium"
            v-model="deploy.pressing"
            placeholder="请选择"
            :disabled="!isDisabled"
          >
            <el-option
              v-for="item in pressingList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>

    <span
      v-if="$store.state.personal.role_id == 1 && state <= 2"
      slot="footer"
      class="dialog-footer"
    >
      <el-button type="success" round @click="check(2)">通过</el-button>
      <el-button type="danger" round @click="check(1)">拒绝</el-button>
    </span>
    <!--state<2 PM只有在草稿阶段可以修改-->
    <!--管理员可以在项目暂停时修改信息-->
    <!-- v-else-if="($store.state.personal.role_id == 2 && state < 2) || ($store.state.personal.role_id == 1 && isRestart)"-->
    <span v-else-if="isDisabled" slot="footer" class="dialog-footer">
      <el-button type="success" round @click="issue">提交</el-button>
    </span>
    <el-dialog width="60%" title="" :visible.sync="innerVisible" append-to-body>
      <el-form
        class="innerInput"
        ref="failObj"
        :model="failObj"
        label-width="150px"
      >
        <el-form-item label="请输入不通过的原因">
          <el-input v-model="failObj.note"></el-input>
        </el-form-item>
        <!-- <el-form-item label="上传附件">
          <el-upload
              action="//ghf.site.yscase.com/api/v1.index/uploadFile"
              list-type="picture-card"
              :headers="token"
              :on-success="success"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="Clickfail">确认</el-button>
          <el-button @click="innerVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-dialog>
</template>
<script>
const ALL = [
  "",
  "",
  "id_pm",
  "id_lead",
  "id_headman",
  "id_workers",
  "id_business",
  "id_contact",
];
export default {
  props: {
    status: { type: Number, default: 0 },
    dialogVisible: Boolean,
    id: [Number, String],
    state: { type: Number, default: 0 },
    isRestart: { type: Boolean, default: false },
  },
  data() {
    return {
      fileList: [],

      // 删除图片
      dialogImageUrl: "",
      dialogVisible1: false,
      failObj: {
        note: "",
        pics: [],
      },
      innerVisible: false,
      isDisabled: false,
      difficultyList: [
        {
          value: 4,
          label: "S级",
        },
        {
          value: 3,
          label: "A级",
        },
        {
          value: 2,
          label: "B级",
        },
        {
          value: 1,
          label: "C级",
        },
      ],
      deployOne: {},
      deploy: {},
      customer: {},
      id_pm: [],
      id_lead: [],
      id_headman: [],
      id_workers: [],
      id_business: [],
      id_contact: [],
      workers: [],
      draftTime: "",
      colorTime: "",
      refiningTime: "",
      progressList: [
        {
          value: 1,
          label: "草图",
        },
        {
          value: 2,
          label: "色稿中",
        },
        {
          value: 3,
          label: "细化中",
        },
        {
          value: 4,
          label: "完稿",
        },
      ],
      pressingList: [
        // {
        //   value: 1,
        //   label: "紧急"
        // },
        // {
        //   value: 2,
        //   label: "正常"
        // },
        {
          value: 3,
          label: "延期",
        },
        {
          value: 4,
          label: "暂停",
        },
      ],
      //限制日期选择范围
      pickerExpireOptions: {
        disabledDate: (time) => {
          return this.dealDisabledDate(time);
        },
      },
      //只能选择当天以及以后的日期
      // pickerOptions0: {
      //   disabledDate: time => {
      //     if (this.deploy.date_start) {
      //       return time.getTime() < new Date(this.deploy.date_start).getTime();
      //     } else if (new Date().getTime()) {
      //       return time.getTime() < new Date().getTime();
      //     }
      //   }
      // },
      pickerOptions1: {
        disabledDate: (time) => {
          if (this.deploy.date_end_draft) {
            return (
              time.getTime() <
              new Date(this.deploy.date_end_draft.replace(/-/g, "/")).getTime()
            );
          } else if (new Date().getTime()) {
            return time.getTime() < new Date().getTime();
          }
        },
      },
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.deploy.date_end_color) {
            return (
              time.getTime() <
              new Date(this.deploy.date_end_color.replace(/-/g, "/")).getTime()
            );
          } else if (new Date().getTime()) {
            return time.getTime() < new Date().getTime();
          }
        },
      },
      pickerOptions3: {
        disabledDate: (time) => {
          if (this.deploy.date_end_refine) {
            return (
              time.getTime() <
              new Date(this.deploy.date_end_refine.replace(/-/g, "/")).getTime()
            );
          } else if (new Date().getTime()) {
            return time.getTime() < new Date().getTime();
          }
        },
      },
    };
  },
  watch: {
    id: {
      handler: function (val) {
        this.id = val;
        this.uploadData(val);
      },
      deep: true,
      immediate: false,
    },
    // "deploy.date_start": function(newv, oldv) {
    //   this.deploy.date_start = newv ? newv : "";
    //   if((this.deploy.date_start != oldv && oldv && this.date_startFlag) || this.date_startFlag){
    //     this.draftTime = "";
    //   }
    //   this.date_startFlag = true;

    //   // if (
    //   //   !(oldv == "" && this.deployOne.date_start != "") &&
    //   //   this.dialogVisible
    //   // ) {
    //   //   this.draftTime = "";
    //   // }
    // },
    "deploy.progress": function (newv, oldv) {
      if (newv != oldv && oldv) {
        // 判断用户选择的阶段 清空选中时间
        if (newv > 3) {
          this.draftTime = "";
          this.colorTime = "";
          this.refiningTime = "";
          [this.deploy.date_start_draft, this.deploy.date_start_draft] = [
            "",
            "",
          ];
          [this.deploy.date_start_color, this.deploy.date_end_color] = ["", ""];
          [this.deploy.date_end_refine, this.deploy.date_end_refine] = ["", ""];
        } else if (newv > 2) {
          this.draftTime = "";
          this.colorTime = "";
          [this.deploy.date_start_draft, this.deploy.date_start_draft] = [
            "",
            "",
          ];
          [this.deploy.date_start_color, this.deploy.date_end_color] = ["", ""];
        } else if (newv > 1) {
          this.draftTime = "";
          [this.deploy.date_start_draft, this.deploy.date_start_draft] = [
            "",
            "",
          ];
        }
      }
    },
    workers() {
      this.deploy.id_workers = this.workers.toString();
    },
    draftTime(newv, oldv) {
      [this.deploy.date_start_draft, this.deploy.date_end_draft] = newv
        ? newv
        : ["", ""];
      if (
        (oldv &&
          [
            this.deploy.date_start_draft,
            this.deploy.date_end_draft,
          ].toString() != oldv.toString() &&
          this.draftTimeFlag) ||
        this.draftTimeFlag
      ) {
        this.colorTime = "";
        [this.date_start_color, this.date_end_color] = ["", ""];
      }
      this.draftTimeFlag = true;
    },
    colorTime(newv, oldv) {
      [this.deploy.date_start_color, this.deploy.date_end_color] = newv
        ? newv
        : ["", ""];
      if (
        (oldv &&
          this.isUpdate &&
          [
            this.deploy.date_start_color,
            this.deploy.date_end_color,
          ].toString() != oldv.toString() &&
          this.colorTimeFlag) ||
        this.colorTimeFlag
      ) {
        this.refiningTime = "";
        [this.deploy.date_start_refine, this.deploy.date_end_refine] = ["", ""];
      }
      this.colorTimeFlag = true;
    },
    refiningTime(newv, oldv) {
      [this.deploy.date_start_refine, this.deploy.date_end_refine] = newv
        ? newv
        : ["", ""];
      if (
        (oldv &&
          [
            this.deploy.date_start_refine,
            this.deploy.date_end_refine,
          ].toString() != oldv.toString() &&
          this.refiningTimeFlag) ||
        this.refiningTimeFlag
      ) {
        this.deploy.date_end = "";
      }
      this.refiningTimeFlag = true;
    },
    dialogVisible: {
      handler: function (val, oldv) {
        console.log("state:", this.state);
        console.log("status:", this.status);

        // let { role_id }= this.$store.state.personal; //身份ID 1.管理 2.PM 3.主美 4.组长 5.画师

        /**判断是否可以输入 */
        // if(role_id == 1 || role_id == 2){
        //   this.isDisabled = true;
        // }else{
        //   this.isDisabled = false;
        // }

        // this.role_id = role_id;

        // if(!val)return;
        // this.uploadData(this.id);
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    Clickfail() {
      this.$common.postInterface(
        "/api/v1.projects/check",
        {
          id: this.id,
          state: 1,
          note: this.failObj.note,
          pics: this.failObj.pics ? this.failObj.pics.join(",") : null,
        },
        this,
        (res) => {
          setTimeout(() => {
            location.reload();
          }, 1000);
          this.$emit("dialogVisible", false);
          console.log(res);
          this.innerVisible = false;
        }
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible1 = true;
    },
    handleRemove(file, fileList) {
      let index = null;
      this.failObj.pics.some((item, idx) => {
        index = idx;
        return item == file.response.data;
      });
      this.failObj.pics.splice(index, 1);
      console.log(file, fileList, this.failObj);
    },
    imgsrc(file) {
      console.log(file);
      let num = file.name.lastIndexOf(".");
      let type = file.name.substr(num);
      if (type === ".jpg" || type === ".png") {
        return file.url;
      } else if (type === ".rar" || type === ".zip") {
        return require("../../assets/img/yasuobao.png");
      } else if (
        type === ".doc" ||
        type === ".docx" ||
        type === ".word" ||
        type === ".rtf"
      ) {
        return require("../../assets/img/word.png");
      } else if (type === ".xls" || type === ".xlsx") {
        return require("../../assets/img/xls.png");
      } else if (type === ".ppt" || type === ".pptx") {
        return require("../../assets/img/ppt.png");
      } else if (type === ".pdf") {
        return require("../../assets/img/pdf.png");
      } else if (type === ".txt") {
        return require("../../assets/img/txt.png");
      }
    },
    success(response) {
      // this.fileList.push({name:'',url:'//ghf.site.yscase.com'+response.data})
      this.failObj.pics.push(response.data);
    },
    handleClose() {
      // this.$confirm("确认关闭？")
      //   .then(() => {
      this.$emit("dialogVisible");
      // })
      // .catch(() => {});
    },
    handleAvatarSuccess(res) {
      this.customer.thumb = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        setmas("上传头像图片只能是 JPG/PNG 格式!", "error", this);
        // this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        setmas("上传头像图片大小不能超过 5MB!", "error", this);

        // this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return isJPG && isLt2M;
    },
    issue() {
      let newDeploy = JSON.parse(JSON.stringify(this.deploy));
      //清除一些无用字段-处理修改接口
      for (let item in newDeploy) {
        newDeploy[item] =
          JSON.stringify(newDeploy[item]) == "null" ? "" : newDeploy[item];
      }
      // newDeploy['is_pause'] = 0;  //代表重启
      delete newDeploy.designs;
      delete newDeploy.designs_cnt;
      delete newDeploy.feedbacks_cnt;
      delete newDeploy.cnt_edit;
      delete newDeploy.date_start_real;
      delete newDeploy.date_refine;
      delete newDeploy.date_success;
      delete newDeploy.date_color;
      delete newDeploy.date_success;
      delete newDeploy.id_headman_date;
      delete newDeploy.id_workers_date;
      delete newDeploy.last_date;
      delete newDeploy.last_date;
      delete newDeploy.cmtlist;
      delete newDeploy.is_pause;
      delete newDeploy.id_headman;
      delete newDeploy.id_lead;

      newDeploy.id_pm = newDeploy.id_pm.join(",");

      // delete newDeploy.workers
      // delete newDeploy.id_workers
      //如果目前是草图阶段
      if (newDeploy.progress >= 2) {
        delete newDeploy.date_start_draft;
        delete newDeploy.date_end_draft;
      }
      //如果目前是细化阶段
      if (newDeploy.progress >= 3) {
        delete newDeploy.date_start_color;
        delete newDeploy.date_end_color;
      }
      //如果目前是完稿阶段
      if (newDeploy.progress >= 4) {
        delete newDeploy.last_update;
        delete newDeploy.date_start_refine;
        delete newDeploy.date_end_refine;
      }
      // if(!newDeploy.date_end) delete newDeploy.date_end;

      console.log("date_end", newDeploy.date_end);

      if (newDeploy.progress < 2) {
        if (!newDeploy.date_end_draft) {
          // newDeploy.$message.error("请选择草稿时间");
          setmas("请选择草稿时间", "error", this);
          return false;
        } else if (!newDeploy.date_end_color) {
          // newDeploy.$message.error("请选择色稿时间");
          setmas("请选择色稿时间", "error", this);
          return false;
        } else if (!newDeploy.date_end_refine) {
          // newDeploy.$message.error("请选择细化时间");
          setmas("请选择细化时间", "error", this);
          return false;
        }
      } else if (newDeploy.progress < 3) {
        if (!newDeploy.date_end_color) {
          // newDeploy.$message.error("请选择色稿时间");
          setmas("请选择色稿时间", "error", this);
          return false;
        } else if (!newDeploy.date_end_refine) {
          // newDeploy.$message.error("请选择细化时间");
          setmas("请选择细化时间", "error", this);

          return false;
        }
      } else if (newDeploy.progress < 4 && !newDeploy.date_end_refine) {
        // newDeploy.$message.error("请选择细化时间");
        setmas("请选择细化时间", "error", this);

        return false;
      }
      if (!newDeploy.date_end) {
        setmas("请选择结束时间", "error", this);
        return false;
      }

      this.$common.postInterface(
        "/api/v1.projects/edit",
        newDeploy,
        this,
        (res) => {
          if (res.status === "200") {
            // this.$message.success("提交成功");
            if (this.isRestart) {
              //重启并关闭
              this.$emit("restartProject");
            } else {
              setmas("提交成功", "success", this);
              this.$emit("dialogVisible", false);
              setTimeout(() => {
                location.reload();
              }, 1000);
              // this.$emit("dialogVisible");
            }
          } else {
            setmas(res.msg, "warning", this);

            // this.$message.warning(res.msg);
          }
        }
      );
    },
    check(state) {
      // console.log(state);
      if (state == 1) {
        this.innerVisible = true;
        // this.$prompt("", "请输入不通过的原因", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   inputPattern: /\S/,
        //   inputErrorMessage: "原因不能为空"
        // })
        //   .then(({ value }) => {
        //     this.$common.postInterface(
        //       "/api/v1.projects/check",
        //       { id: this.id, state: state, note: value },
        //       this,
        //       res => {
        //         setTimeout(() => {
        //           location.reload();
        //         }, 1000);
        //         this.$emit("dialogVisible", false);
        //         console.log(res);
        //       }
        //     );
        //   })
        //   .catch(() => {});
      } else {
        this.$common.postInterface(
          "/api/v1.projects/check",
          { id: this.id, state: state },
          this,
          (res) => {
            console.log(res);
            // this.uploadData(this.id);
            this.$emit("dialogVisible", false);
            setTimeout(() => {
              location.reload();
            }, 1000);
            // this.dialogVisible = false;
          }
        );
      }
    },
    uploadData(id) {
      if (!id) return;
      this.$common.getInterface(
        "/api/v1.projects/getOne",
        { id: id },
        this,
        (res) => {
          let newData = JSON.parse(JSON.stringify(res.data));

          console.log(newData.docs);

          delete newData.date_start;
          for (let k in newData) {
            if(k != 'docs'){
              newData[k] =
              k == "id_pm"
                ? newData[k].split(",").map(Number)
                : !isNaN(newData[k])
                ? Number(newData[k])
                : newData[k];
            }
          }
          this.deploy = newData;
          let docs = newData.docs.filter(e=>e);
          this.fileList = docs.map((e) => {
            return { name: "", url: "//ghf.site.yscase.com" + e };
          });


          let { role_id, id } = this.$store.state.personal; //身份ID 1.管理 2.PM 3.主美 4.组长 5.画师

          this.isEdit = role_id == 1 || newData.id_pm.indexOf(id);
          /**判断是否可以输入 */
          this.isDisabled = this.isEdit != -1;

          this.date_startFlag = false;
          this.draftTimeFlag = false;
          this.colorTimeFlag = false;
          this.refiningTimeFlag = false;

          this.deployOne = Object.assign({}, res.data);
          this.draftTime = [
            res.data.date_start_draft || "",
            res.data.date_end_draft || "",
          ];
          this.colorTime = [
            res.data.date_start_color || "",
            res.data.date_end_color || "",
          ];
          this.refiningTime = [
            res.data.date_start_refine || "",
            res.data.date_end_refine || "",
          ];
          this.workers = res.data.id_workers
            ? res.data.id_workers.split(",").map(Number)
            : [];

          let { id_headman, id_lead, pressing } = res.data || {};

          this.deploy.id_headman = id_headman || "";
          this.deploy.id_lead = id_lead || "";
          this.deploy.pressing = pressing || "";

          this.$common.getInterface(
            "/api/v1.customer/getOne",
            { id: res.data.customer_id },
            this,
            (res) => {
              // console.log(res);
              this.customer = res.data;
            }
          );
          if (!this.id_pm.length) {
            for (let i = 2; i < ALL.length; i++) {
              this.$common.getInterface(
                "/api/v1.workers/getList",
                { role_id: i },
                this,
                (res) => {
                  this[ALL[i]] = res.data.data || "";

                  if (i == ALL.length - 1) {
                    this.id_workers = this.id_workers.concat([
                      ...this.id_headman,
                      ...this.id_lead,
                    ]);
                    // if(this.id_workers)
                    //防止出现下拉列表中没有的，导致显示数字
                    this.workers.forEach((e, key) => {
                      let index = this.id_workers.findIndex((v) => v.id == e);
                      if (index < 0) {
                        this.workers.splice(key, 1);
                      }
                    });
                  }
                  // console.log(this[ALL[i]]);
                }
              );
            }
          }
        }
      );
    },
    // 创建项目的弹窗
    sonFun(data) {
      // this.$store.state.personal.role_id = 3;
      let data1 = {
        title: "",
        type_id: "",
        alink: "",
        level_id: "",
        docs: "",
        descr: "",
        customer_id: "",
        id_pm: "",
        id_lead: "",
        id_headman: "",
        id_workers: "",
        id_business: "",
        id_contact: "",
        date_start_draft: "",
        date_end_draft: "",
        date_start_color: "",
        date_end_color: "",
        date_start_refine: "",
        date_end_refine: "",
        date_end: "",
        state: "",
        progress: "",
        pressing: "",
        refiningTime: "",
        daterange: "",
        draftTime: "",
      };

      this.date_startFlag = false;
      this.draftTimeFlag = false;
      this.colorTimeFlag = false;
      this.refiningTimeFlag = false;

      this.deploy = Object.assign(data1, data);
      this.draftTime = [
        this.deploy.date_start_draft || "",
        this.deploy.date_end_draft || "",
      ];
      this.colorTime = [
        this.deploy.date_start_color || "",
        this.deploy.date_end_color || "",
      ];
      this.refiningTime = [
        this.deploy.date_start_refine || "",
        this.deploy.date_end_refine || "",
      ];
      console.log(this.deploy.id_workers);
      this.workers = [];
      this.deploy.id_headman || (this.deploy.id_headman = "");
      this.$common.getInterface(
        "/api/v1.customer/getOne",
        { id: this.deploy.customer_id ? this.deploy.customer_id : "" },
        this,
        (res) => {
          if (res.data) {
            this.customer = res.data;
          } else {
            this.customer = {
              address: "",
              admin_id: null,
              cnt_begin: "",
              cnt_delay: "",
              cnt_end: "",
              create_time: "",
              customer_id: false,
              finish_per: "",
              hzsj: null,
              lxr: "",
              name: "",
              note: "",
              relas: null,
              status: "",
              tel: "",
              thumb: "",
              total: 0,
            };
          }
        }
      );
      if (!this.id_pm.length) {
        for (let i = 2; i < ALL.length; i++) {
          this.$common.getInterface(
            "/api/v1.workers/getList",
            { role_id: i },
            this,
            (res) => {
              this[ALL[i]] = res.data.data ? res.data.data : "";
              if (i == ALL.length - 1) {
                this.id_workers = this.id_workers.concat([
                  ...this.id_headman,
                  ...this.id_lead,
                ]);
              }
              // console.log(this[ALL[i]]);
            }
          );
        }
      }
    },
  },
  computed: {
    token() {
      return { token: window.sessionStorage.getItem("token") };
    },
    fenpei() {
      return window.sessionStorage.getItem("role_id") != "3";
    },
  },
  created() {},
};
// 提醒弹出框
function setmas(msg, type, vm) {
  vm.$message({
    message: msg,
    type: type,
    customClass: "popup",
  });
}
</script>
<style>
.popup {
  width: 400px;
  height: 70px;
  font-size: 26px;
  font-weight: 700;

  position: absolute !important;
  top: 45% !important;
  margin-top: -35px !important;
}
</style>
<style lang="less" scoped>
@deep: ~">>>";

@{deep}.el-dialog {
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: -8px;
      right: -60px;
      font-size: 40px;
      .el-dialog__close {
        color: #eee;
      }
    }
  }
  .el-dialog__body {
    h6 {
      position: relative;
      display: inline-block;
      margin-left: 20px;
      padding: 0 20px;
      font-size: 20px;
      color: #f8b849;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -27px;
        width: 100%;
        height: 5px;
        background-color: #f8b849;
        border-radius: 5px;
        z-index: 2001;
      }
    }
    .el-row {
      text-align: right;
      width: 90%;
      margin: 0 auto;
      .el-col {
        margin-bottom: 20px;
        .el-select,
        .el-date-editor {
          width: 100%;
        }
        &.radio {
          text-align: left;
          line-height: 36px;
          span {
            text-align: right;
          }
        }
        & > span {
          float: left;
          width: 85px;
          line-height: 36px;
        }
        & > div {
          padding-left: 20px;
          overflow: hidden;

          .avatar-uploader {
            text-align: left;
            .el-upload {
              border: 1px dashed #d9d9d9;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
              img {
                object-fit: cover;
              }
            }
            .el-upload:hover {
              border-color: #409eff;
            }
            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 300px;
              height: 100px;
              line-height: 100px;
              text-align: center;
            }
            .avatar {
              width: 300px;
              height: 100px;
              display: block;
            }
          }
          .avatar-uploader {
            text-align: left;
            .el-upload {
              background-color: #ebebeb;
              border: 1px dashed #d9d9d9;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
              img {
                object-fit: cover;
              }
            }
            .el-upload:hover {
              border-color: #409eff;
            }
            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 300px;
              height: 100px;
              line-height: 100px;
              text-align: center;
            }
            .avatar {
              width: 300px;
              height: 100px;
              display: block;
            }
          }
        }

        &.upload {
          text-align: left;
          & > span {
            text-align: right;
            line-height: 80px;
          }
          .el-upload-list {
            .el-upload-list__item {
              width: 80px;
              height: 80px;
              img {
                background-color: #ebebeb;
                object-fit: scale-down;
              }
            }
          }
          .el-upload {
            width: 80px;
            height: 80px;
            line-height: 88px;
            background-color: #ebebeb;
            display: inline-block;
          }
        }
      }

      .logoBox {
        span {
          line-height: 105px;
        }
      }
    }
  }
}
</style>
